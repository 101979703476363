import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, useTheme } from '@mui/material';

import CustomDivider from '../../themes/customDivider';
import { OneXThemePalette } from '../../types/enums/theme';
import { getOnDemandCustomElementProps } from '../screenUtils';

type ModalProps = {
  children: any;
  closeModal: (e: any, reason: any) => void;
  size: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  show: boolean;
  title?: string;
  hideTitle?: boolean | undefined;
  fullScreen?: boolean | undefined;
  onlyCloseButton?: boolean | undefined;
  scroll?: 'paper' | 'body' | undefined;
  removePadding?: boolean | undefined;
};

function Modal({
  children,
  closeModal,
  show = false,
  title = '',
  size,
  hideTitle = false,
  fullScreen = false,
  onlyCloseButton = false,
  scroll = 'paper',
  removePadding = false,
}: ModalProps) {
  const theme = useTheme<OneXThemePalette>();
  if (!size) size = 'xs';

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={size}
      scroll={scroll}
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={show}>
      {!hideTitle && (
        <>
          <DialogTitle id="dialog-title">
            <Box color="text.primary" sx={{ fontWeight: '600' }}>
              {title}
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => {
                closeModal(null, null);
              }}
              sx={{ position: 'absolute', right: 8, top: 5, color: theme.palette.text.secondary }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <CustomDivider />
        </>
      )}
      {onlyCloseButton && hideTitle && (
        <IconButton
          aria-label="close"
          onClick={() => {
            closeModal(null, null);
          }}
          sx={{ position: 'absolute', right: 8, top: 5, color: theme.palette.text.secondary }}
          {...getOnDemandCustomElementProps('Close', 'Close')}>
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent sx={removePadding ? { padding: 1, margin: 1 } : {}} className="modalDialog">
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default Modal;
