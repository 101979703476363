import React, { useEffect, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';

import LayoutCard from '../../layout/card';
import { LOADING_MESSAGE, LOADING_SIZE, LOADING_TYPE } from '../../types/enums/appState';
import { screenUtils } from '../screenUtils';

import styles from './index.module.css';

interface Props {
  message?: LOADING_MESSAGE;
  type?: LOADING_TYPE;
  size?: LOADING_SIZE;
  delayed?: boolean;
}

const longMessageTime = 20000;
const unresponsiveTime = 120000;
const delayShowTime = 500;

const Loading: React.FC<Props> = ({ message, size, type = LOADING_TYPE.CARD, delayed = true }) => {
  const [loadingMessage, setMessage] = useState<LOADING_MESSAGE | undefined>(message);
  const [delayShow, setDelayShow] = useState<boolean>(delayed);
  const [isFrame] = screenUtils.useIsFrame();

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setMessage(LOADING_MESSAGE.LONG);
    }, longMessageTime);

    let timer2 = setTimeout(() => {
      setMessage(LOADING_MESSAGE.ERROR);
      throw Error('Application in an unresponsive state');
    }, unresponsiveTime);

    let showLoading = setTimeout(() => {
      setDelayShow(false);
    }, delayShowTime);

    return () => {
      clearTimeout(showLoading);
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  if (isFrame) {
    return (
      <Box className={styles.loadingSpinnerFrame}>
        <CircularProgress color="primary" />
      </Box>
    );
  }
  if (type === LOADING_TYPE.CARD) {
    if (delayShow) return null;
    return (
      <LayoutCard style={{ justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress sx={{ alignSelf: 'center' }} thickness={4.2} color="primary" />
        {loadingMessage ? (
          <Box
            color="text.primary"
            style={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: size === LOADING_SIZE.SMALL ? 0 : '1rem',
              fontSize: size === LOADING_SIZE.SMALL ? '.9rem' : '1.2rem',
              fontWeight: 500,
            }}>
            {loadingMessage}
          </Box>
        ) : (
          'Loading'
        )}
      </LayoutCard>
    );
  } else
    return (
      <Box>
        <Box className={styles.loading} sx={{ marginTop: size === LOADING_SIZE.SMALL ? 0 : '4rem' }}>
          <CircularProgress thickness={4.2} color="primary" />
        </Box>
        {loadingMessage && (
          <Box
            color="text.primary"
            style={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: size === LOADING_SIZE.SMALL ? 0 : '1rem',
              fontSize: size === LOADING_SIZE.SMALL ? '.9rem' : '1.2rem',
              fontWeight: 500,
              backgroundColor: 'transparent',
            }}>
            {loadingMessage}
          </Box>
        )}
      </Box>
    );
};

export default React.memo(Loading);
