import { useEffect, useState } from 'react';

import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { getOnDemandCustomElementProps, screenUtils } from '../../../utility/screenUtils';

import styles from './index.module.css';

interface Props {}

const ScrollTopButton: React.FC<Props> = () => {
  const [enabled, setEnabled] = useState<boolean>(true);
  useEffect(() => {
    const showWhenScrolled = () => {
      if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        document.getElementById('scrollTopButton')?.classList.add(styles.show);
        document.getElementById('scrollTopButton')?.classList.remove(styles.hide);
      } else {
        document.getElementById('scrollTopButton')?.classList.add(styles.hide);
        document.getElementById('scrollTopButton')?.classList.remove(styles.show);
      }
    };
    window.addEventListener('scroll', showWhenScrolled);

    return () => {
      window.removeEventListener('scroll', showWhenScrolled);
    };
  });

  const isFrame = screenUtils.isIFrame();

  const scrollToTop = () => {
    setEnabled(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setTimeout(() => {
      setEnabled(true);
    }, 1000);
  };

  if (!isFrame)
    return (
      <Box className={styles.container} color="primary.main" id="scrollTopButton" title="Scroll to Top">
        <Button
          onClick={() => {
            if (enabled) {
              scrollToTop();
            }
          }}
          size="small"
          className={styles.circleButton}
          variant="outlined"
          {...getOnDemandCustomElementProps('btnScrollToTop', 'Scroll to Top')}>
          <VerticalAlignTopIcon fontSize="small" />
          &nbsp;Scroll to Top
        </Button>
      </Box>
    );
  else return null;
};
export default ScrollTopButton;
