import React from 'react';

export const EmailVerification = React.lazy(() => import('./phases/emailVerification'));
export const Info = React.lazy(() => import('./phases/info'));
export const Form = React.lazy(() => import('./features/form'));
export const Equipment = React.lazy(() => import('./features/equipment'));
export const Signature = React.lazy(() => import('./features/signature'));
export const Status = React.lazy(() => import('./phases/status'));
export const MultiOwner = React.lazy(() => import('./phases/multiOwner'));
export const AchDistribution = React.lazy(() => import('./features/achDistribution'));
