import { AchQuestionModel } from '../../features/achDistribution/types';
import { SESSION_ERROR_MESSAGE } from '../enums';
import { CartPackageModel, Package } from '../equipment';
import { QuestionGroup } from '../form';
import { Owner } from '../multiOwner';
import { BasicSessionInfoModel, Session, SessionAddress } from '../session';
import { AgreementModel } from '../signature';
import { StatusPageCardStyle, StatusPageComponents } from '../status';

export const BackButtonText = 'Back';

export type PhaseResponse = Session & {
  navigationAction: PhaseNavigationAction;
  broadcastSenderId?: string;
  err?: string;
  path?: string;
};

export type PhaseProps = {
  isRewind?: boolean;
  rewind: () => void;
  submit: (values: any, type: PhaseType, error?: SESSION_ERROR_MESSAGE) => void;
};

export type ReloadPhaseModelResponse = {
  payload: PhaseTypes;
};

export enum PhaseType {
  INFO = 'Info',
  FORM = 'FormEntry',
  EQUIPMENT = 'EquipmentSelection',
  EMAIL_VERIFICATION = 'EmailVerification',
  SIGNATURE = 'Signature',
  STATUS = 'Status',
  MULTI_OWNER = 'MultiOwner',
  ACH_DISTRIBUTION = 'AchDistribution',
}

export enum CaptchaType {
  SILENT = 'Silent',
  CHECKBOX = 'Checkbox',
}

export enum PrivacyPolicyType {
  GLOBAL = 'Global',
  HEARTLAND = 'Heartland',
}

export enum TrackerType {
  BUBBLE = 'Bubble',
  PILL = 'Pill',
}

export enum PhaseNavigationAction {
  UNKNOWN = 'Unknown',
  PAGE_REDIRECT = 'PageRedirect',
  NEXT_PAGE = 'NextPage',
}

export enum PhaseEvent {
  FORM_PAGE_SUBMIT = 'formPageSubmit',
  FORM_PAGE_VIEW = 'formPageView',
  EQUIPMENT_VIEW_BROWSE = 'equipmentViewBrowse',
  EQUIPMENT_VIEW_CART = 'equipmentViewCart',
  EQUIPMENT_CHECKOUT = 'equipmentCheckout',
  EQUIPMENT_SKIPPED = 'equipmentSkipped',
  INFO_PAGE_VIEW = 'infoPageView',
  ERROR = 'OD_CLIENT_ERROR',
  CAPTCHA_ERROR = 'OD_CAPTCHA_ERROR',
  RETRY = 'OD_CLIENT_RETRY',
  SIGNATURE_AGREEMENT = 'signatureAgreement',
  EMAIL = 'on_demand_email_submit',
  FINISH = 'on_demand_finish',
  CONVERSION = 'on_demand_conversion',
  SESSION_LIST_SHOWN = 'session_list_shown',
  SESSION_LIST_SESSION_RESUMED = 'session_list_session_resumed',
  SESSION_LIST_SESSION_CREATED = 'session_list_session_created',
  MAINTENANCE_MODE = 'OD_MAINTENANCE_MODE',
}

export type Phase = {
  id: number;
  typedPhaseModelId: any;
  onDemandConfigId: number;
  name: string;
  ordinal: number;
  showChatbot: boolean;
  showTracker: boolean;
  allowRewind: boolean;
  submitButtonText: string;
  savedProperties: Array<SavedProperty>;
  phaseType: PhaseType;
  trackerType: TrackerType;
  notificationMessageHtml: string | null;
  captchaType: CaptchaType;
  headerHtml?: string;
  footerHtml?: string;
  infoHtml?: string;
  customHeaderUrl?: string;
  customFooterUrl?: string;
  customBodyUrl?: string;
  contentReloadedTimestamp?: number;
};

export type SavedProperty = {
  propertyKey: string;
  propertyValue: string;
  isSensitive: boolean;
};

export interface FormEntryPhase extends Phase {
  privacyText: string | null;
  id: number;
  name: string;
  questionGroups: Array<QuestionGroup>;
  questionVisibilities: Record<number, boolean>;
  showRequiredFieldNotice: boolean;
  privacyPolicyType?: PrivacyPolicyType;
}
export interface EquipmentPhase extends Phase {
  equipmentApiChannel: string;
  availablePackages: Array<Package> | null;
  landingPage: {
    heroTextHtml?: string;
    heroImageUrl?: string;
    heroPackageId?: number;
    packages: Array<Package> | null;
  };
  cartPackages: Array<CartPackageModel> | null;
  address: SessionAddress;
  userSkippable: boolean;
  basicSessionInfo: BasicSessionInfoModel;
}
export interface InfoPhase extends Phase {
  htmlContent: string;
  imageUrl: string;
  continueButtonText: string;
  continueButtonUrl: string;
  showContinueButton: boolean;
}

export interface EmailVerificationPhase extends Phase {
  currentSessionEmail: string;
}

export interface SignaturePhase extends Phase {
  agreements: Array<AgreementModel>;
}

export interface AchDistributionPhase extends Phase {
  achQuestions: Array<AchQuestionModel>;
}

export interface StatusPhase extends Phase {
  components: Array<StatusPageComponents>;
  pageCardStyle: StatusPageCardStyle;
  statusPageType: string;
}

export interface MultiOwnerPhase extends Phase {
  owners: Array<Owner>;
  minimumTotalPercentage: number;
  minimumIndividualPercentage: number;
  previousAddressPrompt: boolean;
  titlePrompt: boolean;
  allowZeroOwnershipForNonOwners: boolean;
  ssnPrompt: boolean;
  isSoleProprietor: boolean;
  hiddenFirstOwnerProperties: string[];
}

export type PhaseTypes =
  | Phase
  | FormEntryPhase
  | InfoPhase
  | EquipmentPhase
  | EmailVerificationPhase
  | MultiOwnerPhase
  | AchDistributionPhase;
