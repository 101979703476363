import * as dateFns from 'date-fns';

import { getReferenceData } from '../../../api/form';
import { FormFieldSubTypes, FormFieldTypes, KVPTypes, OTHER_MCC } from '../../../types/enums/FormFieldTypes';
import {
  CachedItem,
  FieldValue,
  FormFieldOption,
  LockedPropertyKeys,
  MCC,
  PartnerEcommSoftware,
  PartnerGpiSoftware,
  ProportionOption,
  ProportionOptionJSON,
  Question,
  ReferenceData,
} from '../../../types/form';
import { FormEntryPhase, PhaseType, SavedProperty } from '../../../types/phase';
import { isEmpty } from '../../../utility';
import { ReferenceDataTypes } from '../types';
import { InputBaseComponentProps } from '@mui/material';

interface IFormHelperService {
  getQuestionDefaultValue: (question: Question) => any;
  loadFormEntryPhase: (
    phase: FormEntryPhase,
    cachedData: CachedItem[],
    allowAutoComplete: boolean,
    savedValues: Array<SavedProperty>,
    pageIndex?: number,
  ) => Promise<any>;
  getSavedValue: (question: Question, savedValues: Array<SavedProperty>, referenceData: Array<ReferenceData>) => any;
  createPropertiesPayload: (values: Array<FieldValue>, phaseType: PhaseType) => any;
  getCustomInputProps: (
    fieldType: FormFieldTypes,
    propertyKey: string,
    label?: string,
    fieldSubType?: FormFieldSubTypes,
  ) => InputBaseComponentProps;
}

class FormHelperService implements IFormHelperService {
  getQuestionDefaultValue(question: Question): any {
    const fieldDefinitionJson = JSON.parse(question.fieldDefinition);
    const result: any = {};

    switch (question.fieldType) {
      case FormFieldTypes.KVP:
        const defaultOptions = question.options.filter((x: FormFieldOption) => x.isDefaultOption);
        const defaultOptionsValues = defaultOptions.map((x: FormFieldOption) => x.value);

        result.value = defaultOptionsValues;

        if (defaultOptions.length === 1) {
          if (fieldDefinitionJson.KVPType === KVPTypes.DROPDOWN) {
            result.value = fieldDefinitionJson?.MultiSelect
              ? defaultOptionsValues
              : { value: defaultOptions[0].value, label: defaultOptions[0].label };
          } else {
            result.value = fieldDefinitionJson?.MultiSelect ? defaultOptionsValues : defaultOptionsValues[0];
          }
        } else if (defaultOptions.length < 1) {
          result.value = fieldDefinitionJson?.MultiSelect ? [''] : '';
        }

        break;
      case FormFieldTypes.PROPORTION:
        const options = fieldDefinitionJson?.Properties;

        type ProportionOptions = {
          option: string;
          value: string;
        };
        const setup: Array<ProportionOptions> = [];

        options.forEach((e: any) => {
          setup.push({ option: e.Label, value: '' });
        });

        result.value = { total: fieldDefinitionJson?.Total, options: setup };
        break;
      case FormFieldTypes.SMART_LOCATION:
        result.value = { address: null, state: null, city: null, zip: null };
        break;
      case FormFieldTypes.EQUIPMENT:
        const equipment = fieldDefinitionJson?.Properties;
        const startingQuantity = question.isRequired ? 1 : 0;
        let equipmentSetup: Array<any> = [];
        equipment.forEach(() => {
          equipmentSetup.push(startingQuantity);
        });
        result.value = equipmentSetup;
        break;
      case FormFieldTypes.SLIDER:
        const multiplier = fieldDefinitionJson?.Multiplier ?? 1;
        result.value = fieldDefinitionJson?.Min ? fieldDefinitionJson.Min * multiplier : 0;
        break;
      default:
        result.value = '';
        break;
    }
    return result;
  }

  async loadFormEntryPhase(
    phase: FormEntryPhase,
    cachedData: CachedItem[],
    allowAutoComplete: boolean,
    savedValues: Array<SavedProperty>,
    pageIndex?: number,
  ): Promise<any> {
    const values: Array<FieldValue> = [];

    let pageRequiredText = false;

    for (const group of phase.questionGroups) {
      for (const question of group.questions) {
        let referenceData: Array<ReferenceData> = [];

        if (question.isRequired && phase.showRequiredFieldNotice === true) {
          pageRequiredText = true;
        }

        if (question.fieldType === FormFieldTypes.MCC) {
          let result: Array<MCC> = await getReferenceData(ReferenceDataTypes.MCC).catch((error) => {
            throw new Error(`Error getting MCC reference data: ${error}`);
          });
          const otherOption: MCC = { Code: '0', Name: OTHER_MCC };
          referenceData = [...result, otherOption];
        }

        if (question.fieldType === FormFieldTypes.PARTNER_ECOMM_SOFTWARE) {
          referenceData = await getReferenceData(ReferenceDataTypes.PARTNER_ECOMM_SOFTWARE).catch((error) => {
            throw new Error(`Error getting Partner Ecomm Software reference data: ${error}`);
          });
        }

        if (question.fieldType === FormFieldTypes.PARTNER_GPI_SOFTWARE) {
          referenceData = await getReferenceData(ReferenceDataTypes.PARTNER_GPI_SOFTWARE).catch((error) => {
            throw new Error(`Error getting Partner GPI Software reference data: ${error}`);
          });
        }

        const savedValue = this.getSavedValue(question, savedValues, referenceData);
        const cachedValue = cachedData.find((cd) => cd.id === question.id && cd.questionGroupId === group.id);
        const hasCachedValue = cachedValue !== undefined;
        const defaultValue =
          savedValue?.value != null ? savedValue : cachedValue ?? this.getQuestionDefaultValue(question);

        values.push({
          autoComplete: allowAutoComplete ? 'on' : 'new-password',

          fieldType: question.fieldType,
          fieldDefinition: question.fieldDefinition,
          isRequired: question.isRequired,
          label: question.label,
          isSensitive: savedValue?.isSensitive,
          options: question.options.sort((a, b) => a.label.localeCompare(b.label)),
          ordinal: question.ordinal,
          questionId: question.id,
          questionParentId: question.parentFormQuestionId,
          questionGroupId: group.id,
          questionGroupOrdinal: group.ordinal,
          value: defaultValue.value ?? '',
          validationError: false,
          validationErrorText: '',
          referenceData: referenceData.length > 0 ? referenceData : undefined,
          confirmationValue: savedValue?.confirmationValue ?? '',
          confirmationError: false,
          locked: savedValue?.isLocked,
          hasVisibilityDependency: question?.hasVisibilityDependency,
          visible: Boolean(
            hasCachedValue ||
              phase.questionVisibilities[question.id] ||
              (question.parentFormQuestionId && phase.questionVisibilities[question.parentFormQuestionId]),
          ),
        });
      }
    }

    return {
      phase,
      newValues: values,
      pageRequiredText,
    };
  }

  getSavedValue(question: Question, savedValues: Array<SavedProperty>, referenceData: Array<ReferenceData>): any {
    let savedValue: any = null;
    let isSensitive: boolean = false;
    let confirmationValue: string | undefined = undefined;
    let isLocked = false;
    const fieldDefinition = JSON.parse(question.fieldDefinition);

    if (savedValues.length === 0) return null;

    switch (question.fieldType) {
      case FormFieldTypes.MATRIX:
        break;
      case FormFieldTypes.STATE:
        let state = savedValues.find((x) => x.propertyKey === fieldDefinition.Properties[0].PropertyKey);
        if (state) {
          savedValue = { value: JSON.parse(state.propertyValue).value, label: JSON.parse(state.propertyValue).label };
        }
        break;
      case FormFieldTypes.PARTNER_ECOMM_SOFTWARE:
        let ecommVal = savedValues.find(
          (x) => x.propertyKey === fieldDefinition.Properties[0].PropertyKey,
        )?.propertyValue;
        if (ecommVal) {
          const formFieldOption: FormFieldOption = {
            label:
              (referenceData as Array<PartnerEcommSoftware>).find((x) => x.AffiliateId === ecommVal)
                ?.PartnerEcommSoftware ?? '',
            value: ecommVal,
            ordinal: 0,
            formFieldQuestionId: question.id,
            id: 0,
            isDefaultOption: false,
            questions: [],
          };
          savedValue = formFieldOption;
        }
        break;
      case FormFieldTypes.PARTNER_GPI_SOFTWARE:
        let gpiVal = savedValues.find(
          (x) => x.propertyKey === fieldDefinition.Properties[0].PropertyKey,
        )?.propertyValue;
        if (gpiVal) {
          const formFieldOption: FormFieldOption = {
            label:
              (referenceData as Array<PartnerGpiSoftware>).find((x) => x.PartnerProductVersionId === gpiVal)
                ?.PartnerGpiSoftware ?? '',
            value: gpiVal,
            ordinal: 0,
            formFieldQuestionId: question.id,
            id: 0,
            isDefaultOption: false,
            questions: [],
          };
          savedValue = formFieldOption;
        }
        break;
      case FormFieldTypes.KVP:
        let foundValue = savedValues.find(
          (x) => x.propertyKey === fieldDefinition.Properties[0].PropertyKey,
        )?.propertyValue;
        if (foundValue) {
          savedValue = JSON.parse(foundValue)?.Value;

          if (fieldDefinition.MultiSelect) {
            let valueArray: Array<string> = [];
            JSON.parse(foundValue).forEach((v: { Label: string; Value: string }) => {
              const option = question.options.find((op) => op.value === v.Value);

              if (option) {
                valueArray.push(v.Value);
              }
            });
            savedValue = valueArray;
          }

          if (fieldDefinition.KVPType === KVPTypes.DROPDOWN && !fieldDefinition.MultiSelect) {
            const autoCompleteValue = JSON.parse(foundValue);
            const option = question.options.find((op) => op.value === autoCompleteValue.Value);

            if (option) {
              savedValue = { value: autoCompleteValue.Value, label: autoCompleteValue.Label };
            } else {
              savedValue = null;
            }
          }
        }
        break;
      case FormFieldTypes.PROPORTION:
        let options: Array<ProportionOption> = [];
        fieldDefinition.Properties.forEach((x: { PropertyKey: string; Label: string }) => {
          let match = savedValues.find((s) => s.propertyKey === x.PropertyKey);
          if (match) {
            options.push({
              option: x.Label,
              value: parseInt(match.propertyValue),
              userModified: true,
            });
          }
        });
        if (options.length > 0) savedValue = { options, total: fieldDefinition.Total };
        break;
      case FormFieldTypes.MCC:
        let mccValue: { Code: string; Name: string } = { Code: '', Name: '' };
        let mccMatch = savedValues.find((s) => s.propertyKey === fieldDefinition.Properties[0].PropertyKey);
        if (mccMatch) {
          mccValue!.Code = JSON.parse(mccMatch.propertyValue).Code;
          mccValue!.Name = JSON.parse(mccMatch.propertyValue).Name;
          savedValue = mccValue;
        }

        break;
      case FormFieldTypes.EQUIPMENT:
        let value: Array<number> = [];
        fieldDefinition.Properties.forEach((x: { PropertyKey: string; Label: string }) => {
          let match = savedValues.find((s) => s.propertyKey === x.PropertyKey);
          if (match) {
            value.push(parseInt(match.propertyValue));
          } else {
            value.push(question.isRequired ? 1 : 0);
          }
        });
        savedValue = value;
        break;
      case FormFieldTypes.DATE:
        let dateMatch = savedValues.find((x) => x.propertyKey === fieldDefinition.Properties[0].PropertyKey);
        if (dateMatch) {
          savedValue = new Date(dateMatch.propertyValue);
        }
        break;
      case FormFieldTypes.DATE_RANGE:
      case FormFieldTypes.TIME_RANGE:
        let dateRange = { range1: '', range2: '' };
        let match1 = savedValues.find((s) => s.propertyKey === fieldDefinition.Properties[0].PropertyKey);
        let match2 = savedValues.find((s) => s.propertyKey === fieldDefinition.Properties[1].PropertyKey);
        if (match1 && match2) {
          dateRange.range1 = match1.propertyValue;
          dateRange.range2 = match2.propertyValue;
        }
        savedValue = dateRange;
        break;
      case FormFieldTypes.SMART_LOCATION:
        let match = savedValues.find((s) => s.propertyKey === fieldDefinition.Properties[0].PropertyKey);
        if (match) savedValue = { address: match?.propertyValue, city: null, state: null, zip: null };
        break;
      default:
        savedValue = savedValues.find(
          (x) => x.propertyKey === fieldDefinition.Properties[0].PropertyKey,
        )?.propertyValue;
        isSensitive =
          savedValues.find((x) => x.propertyKey === fieldDefinition.Properties[0].PropertyKey)?.isSensitive ?? false;
        if (!isEmpty(savedValue)) {
          if (LockedPropertyKeys.find((x) => x === fieldDefinition.Properties[0].PropertyKey) != null) {
            isLocked = true;
          }
        }
        if (fieldDefinition?.RequiresConfirmation) confirmationValue = savedValue;
        break;
    }

    return {
      value: savedValue,
      confirmationValue: confirmationValue,
      isSensitive: isSensitive,
      isLocked: isLocked,
    };
  }

  createPropertiesPayload(values: Array<FieldValue>, phaseType: PhaseType): any {
    const InputValues: any = {};

    if (phaseType === PhaseType.FORM)
      values
        .filter((_) => _.visible)
        .forEach((x) => {
          let PropertyKey = null;
          switch (x.fieldType) {
            case FormFieldTypes.MATRIX:
              break;
            case FormFieldTypes.SMART_LOCATION:
              PropertyKey = JSON.parse(x.fieldDefinition).Properties[0].PropertyKey;

              if (x.value?.address !== '' && x.value?.address !== null && !isEmpty(x.value?.address)) {
                Object.assign(InputValues, {
                  [PropertyKey]: x.value.address,
                });
              }
              break;
            case FormFieldTypes.EQUIPMENT:
              const equipment = JSON.parse(x.fieldDefinition).Properties;
              equipment.forEach((o: { PropertyKey: string }, i: number) => {
                if (x.value[i] !== 0) {
                  Object.assign(InputValues, {
                    [o.PropertyKey]: '' + x.value[i] + '',
                  });
                }
              });
              break;
            case FormFieldTypes.PROPORTION:
              const options = JSON.parse(x.fieldDefinition).Properties;

              options.forEach((o: ProportionOptionJSON) => {
                Object.assign(InputValues, {
                  [o.PropertyKey]: JSON.stringify(x.value.options.find((op: any) => op.option === o.Label)?.value || 0),
                });
              });
              break;
            case FormFieldTypes.STATE:
              if (!isEmpty(x.value)) {
                PropertyKey = JSON.parse(x.fieldDefinition).Properties[0].PropertyKey;
                Object.assign(InputValues, {
                  [PropertyKey]: JSON.stringify({ label: x.value.label, value: x.value.value }),
                });
              }
              break;
            case FormFieldTypes.PARTNER_ECOMM_SOFTWARE:
              PropertyKey = JSON.parse(x.fieldDefinition).Properties[0].PropertyKey;
              Object.assign(InputValues, { [PropertyKey]: x.value.value });
              break;
            case FormFieldTypes.KVP:
              let fieldDefinition = JSON.parse(x.fieldDefinition);
              PropertyKey = JSON.parse(x.fieldDefinition).Properties[0].PropertyKey;
              if (!isEmpty(x.value)) {
                if (fieldDefinition.KVPType === KVPTypes.DROPDOWN && !fieldDefinition.MultiSelect) {
                  Object.assign(InputValues, {
                    [PropertyKey]: JSON.stringify({
                      label: x.value.label,
                      value: x.value.value,
                    }),
                  });
                } else if (fieldDefinition.MultiSelect) {
                  let selections: any = [];
                  x.value.forEach((selection: any) => {
                    if (!isEmpty(selection)) {
                      selections.push({
                        label: x.options.find((op) => op.value === selection)?.label,
                        value: selection,
                      });
                    }
                  });
                  Object.assign(InputValues, {
                    [PropertyKey]: JSON.stringify(selections),
                  });
                } else {
                  Object.assign(InputValues, {
                    [PropertyKey]: JSON.stringify({
                      label: x.options.find((op) => op.value === x.value)?.label,
                      value: x.value,
                    }),
                  });
                }
              }
              break;
            case FormFieldTypes.PARTNER_GPI_SOFTWARE:
              PropertyKey = JSON.parse(x.fieldDefinition).Properties[0].PropertyKey;
              Object.assign(InputValues, { [PropertyKey]: x.value.value });
              break;
            case FormFieldTypes.MCC:
              PropertyKey = JSON.parse(x.fieldDefinition).Properties[0].PropertyKey;
              if (!isEmpty(x.value.Code))
                Object.assign(InputValues, {
                  [PropertyKey]: JSON.stringify({ Code: x.value.Code, Name: x.value.Name }),
                });
              break;
            case FormFieldTypes.DATE_RANGE:
            case FormFieldTypes.TIME_RANGE:
              if (!isEmpty(x.value.range1)) {
                Object.assign(InputValues, {
                  [JSON.parse(x.fieldDefinition).Properties[0].PropertyKey]: x.value.range1,
                });
              }
              if (!isEmpty(x.value.range2))
                Object.assign(InputValues, {
                  [JSON.parse(x.fieldDefinition).Properties[1].PropertyKey]: x.value.range2,
                });
              break;
            case FormFieldTypes.DATE:
              PropertyKey = JSON.parse(x.fieldDefinition).Properties[0].PropertyKey;
              if (x.value && dateFns.isDate(new Date(x.value))) {
                Object.assign(InputValues, { [PropertyKey]: x.value });
              }
              break;
            case FormFieldTypes.SLIDER:
              if (!isEmpty(x.value)) {
                PropertyKey = JSON.parse(x.fieldDefinition).Properties[0].PropertyKey;
                Object.assign(InputValues, { [PropertyKey]: x.value.toString() });
              }
              break;
            default:
              if (!isEmpty(x.value)) {
                PropertyKey = JSON.parse(x.fieldDefinition).Properties[0].PropertyKey;
                Object.assign(InputValues, { [PropertyKey]: x.value });
              }
          }
        });
    return InputValues;
  }

  getCustomInputProps(
    fieldType: FormFieldTypes,
    propertyKey: string,
    label?: string,
    fieldSubType?: FormFieldSubTypes,
  ): InputBaseComponentProps {
    const prefix = 'data-od';
    const inputProps: InputBaseComponentProps = {
      [`${prefix}-type`]: fieldType,
      [`${prefix}-key`]: propertyKey,
    };

    if (label) {
      inputProps[`${prefix}-label`] = label;
    }

    if (fieldSubType) {
      inputProps[`${prefix}-sub-type`] = fieldSubType;
    }

    return inputProps;
  }
}

const formHelperService: IFormHelperService = new FormHelperService();

export type { IFormHelperService };
export { FormHelperService, formHelperService };
